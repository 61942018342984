<template>
  <div>
    <slot></slot>
    <BottomNav />
  </div>
</template>

<script setup lang="ts">
useNewkiSeo()
</script>

<style scoped lang="scss"></style>
